<template>
  <b-card>
    <g-form @submit="save">
      <b-row class="p-0 mb-1">
        <b-col cols="4" v-if="!selectedItem.postedVoucherId"></b-col>
        <b-col cols="4" v-if="selectedItem.postedVoucherId">
          <b-button
            :variant="'dark'"
            data-action-type="new"
            class="mr-1 btn-sm"
            @click="
              (v) => {
                this.$router.push({
                  name: 'vouchers-edit',
                  params: { id: selectedItem.postedVoucherId },
                });
              }
            "
          >
            {{`${$t('voucherNum')} ${selectedItem.voucherCode}`}}
          </b-button>
        </b-col>
      </b-row>
      <hr class="border-bottom-primary border-darken-2" />
      <b-row>
        <!-- code  -->
        <b-col
          v-show="selectedItem.code > 0"
          md="4"
        >
          <b-form-group>
            <g-field
              id="code"
              type="number"
              name="code"
              label-text="code"
              readonly
              :value.sync="selectedItem.code"
            />
          </b-form-group>
        </b-col>

        <!-- transaction date  -->
        <b-col
          v-if="!currentBranch.setDefaultDate"
          md="4"
        >
          <g-field
            :value="getDate(selectedItem.transactionDate)"
            label-text="date"
            disabled
            name="transactionDate"
          />
        </b-col>

        <b-col
          v-else
          md="4"
        >
          <g-picker
            :value.sync="selectedItem.transactionDate"
            label-text="date"
            name="transactionDate"
          />
        </b-col>

        <!-- transaction time  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              label-text="transactionTime"
              name="transactionTime"
              readonly
              :value.sync="selectedItem.transactionTime"
            />
          </b-form-group>
        </b-col>

        <!-- student  -->
        <b-col md="4">
          <b-form-group>
            <student-autocomplete
            name="student"
            rules="required"
            :disabled="selectedItem.financialTransactionAllocations.length > 0"
            :value.sync="selectedItem.studentId"
            url="Students/getStudentsForVoucher"
            :display-item="{
              id: selectedItem.studentId,
              uid: selectedItem.studentId,
              arabicName: selectedItem.studentArabicName,
            }"
            @change:action="(val) =>  filterFromStudents(val)"
          />
          </b-form-group>
        </b-col>
        <!-- guardian  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="guardianName"
              ref="guardianName"
              name="guardianName"
              label-text="guardianName"
              readonly
              :value.sync="guardianName"
            />
          </b-form-group>
        </b-col>

        <!-- guardian mobile number -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="guardianMobile"
              ref="guardianMobile"
              name="guardianMobile"
              label-text="guardianMobile"
              readonly
              :value.sync="guardianMobileNumber"
            />
          </b-form-group>
        </b-col>

        <!-- Remaining Balance  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="remainingBalance"
              ref="remainingBalance"
              name="remainingBalance"
              label-text="studentBalance"
              readonly
              :value.sync="remainingBalance"
            />
          </b-form-group>
        </b-col>

        <!-- voucher value  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              label-text="value"
              type="number"
              rules="required"
              :disabled="
                selectedItem.financialTransactionAllocations.length > 0
              "
              :value.sync="selectedItem.voucherValue"
            />
          </b-form-group>
        </b-col>

        <!-- original value -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              label-text="originalValue"
              type="number"
              readonly
              :value.sync="selectedItem.originalValue"
            />
          </b-form-group>
        </b-col>

        <!-- tax value -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="number"
              type="number"
              label-text="taxValue"
              readonly
              :value.sync="selectedItem.taxValue"
            />
          </b-form-group>
        </b-col>

        <!-- payment method  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="Payment Method"
              name="paymentMethod"
              :options="paymentWays"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.paymentMethod"
            />
          </b-form-group>
        </b-col>

        <!-- safe payment  -->
        <b-col v-if="selectedItem.paymentMethod == 'cash'" md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="safeName"
              name="safePayment"
              :options="safes"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.safeId"
            />
          </b-form-group>
        </b-col>

        <!-- other payments  -->
        <b-col v-if="selectedItem.paymentMethod == 'other'" md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="PaymentMethods"
              name="PaymentMethods"
              :options="payments"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.paymentMethodId"
              @change="
                (v) => {
                  selectedItem.bankId = v.bankId;
                }
              "
            />
          </b-form-group>
        </b-col>

        <!-- bank  -->
        <b-col v-if="selectedItem.paymentMethod == 'other'" md="4">
          <b-form-group>
            <g-field
              field="select"
              label="arabicName"
              label-text="bankName"
              name="bank1"
              disabled
              :options="Banks"
              :dir="isRight ? 'rtl' : 'ltr'"
              :value.sync="selectedItem.bankId"
            />
          </b-form-group>
        </b-col>

        <!-- setDefaultDate  -->
        <b-col
          v-if="this.$route.params.type === 'collection' ||
            this.$route.name === 'collection-transactions-edit' ||
            this.$route.name === 'collection-transactions-new'"
          md="4"
        >
          <b-form-group :label="$t('advanceRevenue')">
            <b-form-checkbox
              v-model="selectedItem.isAdvanceRevenue"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- notes -->
      <b-row class="pt-1">
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-modal
        no-close-on-backdrop
        ref="inv-modal"
        cancel-variant="outline-secondary"
        centered
        size="xl"
        hide-footer
        :title="$t('allInvoice')"
        @hidden="
          () => {
            selectedItem.financialTransactionAllocations = [];
          }
        "
      >
        <b-row>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("allocationValue") }} :
              <span class="text-primary">{{
                id
                  ? selectedItem.voucherValue
                  : selectedItem.voucherValue
              }}</span>
            </h2>
          </b-col>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("allocatedValue") }} :
              <span class="text-primary">{{ allPaid }}</span>
            </h2>
          </b-col>
          <b-col md="4">
            <h2 class="text-center">
              {{ $t("remining") }} :
              <span class="text-primary">{{
                id
                  ? (selectedItem.voucherValue - lastPaid) - allPaid
                  : selectedItem.voucherValue - allPaid
              }}</span>
            </h2>
          </b-col>
        </b-row>
        <g-table
          ref="items-table"
          :items="invoiceProvider"
          :columns="tableColumns"
          v-model="itemsArray"
          :is-busy="isTableBusy"
          :noAction="true"
          perPage="25"
          :totalRows="totalRows"
          :createButton="{ visiable: false }"
          :excelButton="{ visiable: false }"
          :pdfButton="{ visiable: false }"
          :searchInput="{ visiable: false }"
          :tbody-tr-class="
            (item) => {
              if (item && item.net - item.paid === 0) return 'table-success';
            }
          "
        >
          <template #actions="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.voucherValue"
              type="number"
              :disabled="item.net - item.paid === 0"
              :rules="`min_value:0|max_value:${item.remainder}`"
              :shortDesc="true"
              @keyup="
                (v) => {
                  updateFinancialTransactionAllocations(item, itemsArray);
                  if (item.voucherValue > item.remainder) {
                    moreThan = true;
                  } else {
                    moreThan = false;
                  }
                }
              "
              size="sm"
              :style="{ width: '100px' }"
            />
          </template>
        </g-table>
        <hr />
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            @click="() => {
              if (!isValidVoucherValuePaid()) { return }
              save()
            }"
            data-action-type="save"
            :disabled=" allPaid === 0 || moreThan"
            variant="primary"
            v-permission="$route.meta.permission"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-modal>
      <!-- operations -->
      <b-row>
        <b-col
          md="4"
          class="cancel-allocation d-flex justify-content-start"
        >
          <b-button
            v-if="id && selectedItem.financialTransactionAllocations.length > 0"
            variant="adn"
            @click="
              () => {
                selectedItem.financialTransactionAllocations = [];
                save('cancel');
              }
            "
          >
            {{ $t("cancelAlloucation") }}
          </b-button>
        </b-col>

        <b-col
          md="8"
          class="d-flex justify-content-end"
        >
          <b-button
            v-permission="$route.meta.permission"
            type="submit"
            data-action-type="save"
            variant="primary"
          >
            {{ $t("save") }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            :disabled="!selectedItem.voucherValue"
            style="margin: 0 10px;"
            variant="primary"
            data-action-type="saveAndPrint"
            @click="save('saveAndPrint')"
          >
            {{ $t("saveAndPrint") }}
          </b-button>
          <b-button
            @click="
              () => {
                if (!isValidPayment()) { return }
                openInvModal();
                this.allPaid = 0;
              }
            "
            data-action-type="saveAndCustomize"
            variant="gradient-primary"
            :disabled="!selectedItem.studentId || !selectedItem.paymentMethod"
            v-permission="$route.meta.permission"
            v-if="
              this.$route.params.type === 'collection' ||
              this.$route.name === 'collection-transactions-edit' ||
              this.$route.name === 'collection-transactions-new'
            "
          >
            {{ $t("saveAndCustomize") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import { paymentWays } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';
import StudentAutocomplete from '@/components/StudentAutoComplete.vue'

export default {
  components: {
    GTable,
    StudentAutocomplete
  },
  mixins: [
    reportMixin,
  ],
  props: ['id'],
  data() {
    return {
      items: [],
      selectedItem: {
        transactionDate: '',
        voucherType: '',
        paymentMethod: 'cash',
        financialTransactionAllocations: [],
        isAdvanceRevenue: false
      },
      guardians: [],
      students: [],
      bankName: null,
      Banks: [],
      safes: [],
      payments: [],
      paymentMethods: [],
      nationalities: [],
      paymentWays: paymentWays,
      pathName: this.$route.name,
      guardianName: null,
      guardianMobileNumber: null,
      isTaxAppliedOnStudent: null,
      remainingBalance: 0,
      financialTransactionAllocations: [],
      filter: {
        transactionType: '',
        itemTransactionPaymentNature: '3',
        id: null,
        studentId: null,
        storeId: null,
        ItemId: null,
        FromDate: null,
        toDate: null,
        orderClause: null,
        category: '',
        Code: null
      },
      isTableBusy: false,
      totalRows: 0,
      totalRemainder: 0,
      totalPaid: 0,
      totalDiscount: 0,
      totalNet: 0,
      totalAllocatedValue: 0,
      itemsArray: [],
      hideActions: false,
      moreThan: false,
      allPaid: 0,
      lastPaid: 0,
      checkPerm: false,
      transactionDate: this.today,
      alldata: [],
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'lineSerial',
          field: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: this.isRight ? 'serviceName' : 'serviceName',
          field: this.isRight ? 'serviceName' : 'serviceName',
          label: this.$t('serviceName'),
          sortable: true,
        },
        {
          key: 'transactionDate',
          field: 'transactionDate',
          label: this.$t('invoiceDate'),
          sortable: true,
        },
        {
          key: 'net',
          field: 'net',
          label: this.$t('net'),
          sortable: true,
          footer: () => this.fraction(this.totalNet)
        },
        {
          key: 'allocatedValue',
          field: 'allocatedValue',
          label: this.$t('paid'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalPaid)
        },
        {
          key: 'allocateDiscount',
          field: 'allocateDiscount',
          label: this.$t('discount'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalDiscount)
        },
        {
          key: 'remainder',
          field: 'remainder',
          label: this.$t('remaindered'),
          sortable: false,
          thClass: this.filter.transactionType === 'quotation' ? 'd-none' : '',
          tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalRemainder)
        },
        {
          key: 'actions',
          label: this.$t('actions'),
          sortable: false,
          thStyle: { width: '200px' },
        },
      ];
    },
  },
  watch: {
    'selectedItem.paymentMethod'(newval) {
      if (newval === 'cash') {
        this.selectedItem.bankId = null;
        this.selectedItem.paymentMethodId = null;
        this.bankName = null
      } else if (newval === 'other') {
        this.selectedItem.safeId = null;
      }
    },

    'selectedItem.voucherValue'(value) {
      if (value === '') {
        this.selectedItem.originalValue = 0;
        this.selectedItem.taxValue = 0;
      }
      this.calculateTaxAndOrifinalValue(value);
    },

    isTaxAppliedOnStudent() {
      this.calculateTaxAndOrifinalValue(this.selectedItem.voucherValue);
    },

    'selectedItem.financialTransactionAllocations'(oldval, newval) {
      newval.forEach(data => {
        const filterdItem = this.itemsArray.find((val) => val.lineSerial === data.lineSerial && val.serviceId === data.serviceId);
        const number = Number(data.allocatedValue) ? Number(data.voucherValue) - Number(data.allocatedValue) : Number(data.voucherValue);
        const mainVal = this.id ? (this.selectedItem.voucherValue - this.lastPaid) - this.allPaid : this.selectedItem.voucherValue - this.allPaid
        if ((Number(filterdItem.remainder) !== 0 && (number > Number(filterdItem.remainder) || Number(data.value) > Number(filterdItem.remainder))) || mainVal < 0) {
          this.moreThan = true;
          this.doneAlert({ text: this.$t('allocationValueIsMoreThanMainValue'), type: 'warning' });
        } else if ((number <= Number(filterdItem.remainder) && Number(filterdItem.remainder) !== 0) || mainVal < 0) {
          this.moreThan = false;
        }
      });
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    const id = this.$route.query.studentId;
    if (id > 0) {
      this.selectedItem.studentId = id;
      this.selectedItem.studentArabicName = this.$route.params.name;
    }

    if (this.$route.name === 'payment-transactions-new' || this.$route.name === 'payment-transactions-edit') {
      this.selectedItem.voucherType = 'paymentDebenture';
      this.selectedItem.nature = 'prevBalance';
    } else if (this.$route.name === 'collection-transactions-new' || this.$route.name === 'collection-transactions-edit') {
      this.selectedItem.voucherType = 'collectionDebenture'
      this.selectedItem.nature = 'prevBalance';
    }

    this.selectedItem.transactionDate = this.today;
    this.selectedItem.chequeDate = this.today;
    this.selectedItem.transactionTime = this.getTime();

    this.loadObj();
    if (this.id > 0) {
      this.getData();
    }
  },
  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    invoiceProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;
      params += this.getFilterObj(this.filter);
      if (this.filter.studentId === null) {
        this.filter.studentId = this.selectedItem.studentId;
      }
      this.isTableBusy = true;
      this.get({ url: `Invoices/services/${this.filter.studentId}${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          this.totalNet = 0;
          this.totalRemainder = 0;
          this.totalPaid = 0;
          this.totalDiscount = 0;
          data.forEach((item) => {
            item.paid = 0;
            this.totalPaid += (item.paid + item.allocatedValue);
            this.totalNet += (item.net - item.returnTotal);
            item.allocatedValue = item.allocatedValue ? item.allocatedValue : 0;
            this.totalDiscount = item.allocateDiscount ? item.allocateDiscount : 0;
            this.totalRemainder += (item.net - (item.paid + item.allocatedValue + item.allocateDiscount + item.returnTotal));
            item.paid = this.fraction(item.paid + item.allocatedValue);
            item.remainder = this.fraction(item.net - (item.allocatedValue + item.allocateDiscount + item.returnTotal));
            item.transactionDate = this.getDate(item.transactionDate);
            item.net = Number(this.fraction(item.net - item.returnTotal));
            item.value = 0;
          });
          this.itemsArray = data;
          if (this.alldata.length) {
            this.selectedItem.financialTransactionAllocations.forEach(elem => {
              const selectedItem = this.alldata.find((val) => val.id === elem.itemTransactionId);
              const selectedmain = data.find((val) => val.id === selectedItem.id);
              if (!selectedmain) return data.push(selectedItem);
            });
          }
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },

    loadObj() {
      // this.getStudents();
      this.getBanks();
    },

    updateFinancialTransactionAllocations(item, items) {
      this.allPaid = 0;
      items.forEach(row => {
        this.allPaid += parseFloat(row.voucherValue) || 0;
      });
      const selectedItem = this.selectedItem.financialTransactionAllocations.find((val) => val.lineSerial === item.lineSerial && val.serviceId === item.serviceId && val.itemTransactionId === item.id);
      if (!selectedItem && item.voucherValue > 0) {
        this.selectedItem.financialTransactionAllocations.push(
          {

            lineSerial: item.lineSerial,
            serviceId: item.serviceId,
            allocatedValue: item.allocatedValue,
            itemTransactionId: item.id,
            value: item.voucherValue,
            financialTransactionId: this.id ? this.id : 0
          }
        )
      } else if (item.voucherValue === 0 && selectedItem) this.selectedItem.financialTransactionAllocations = this.selectedItem.financialTransactionAllocations.filter((val) => val !== selectedItem);
      else if (selectedItem) {
        const indx = this.selectedItem.financialTransactionAllocations.indexOf(selectedItem);
        this.selectedItem.financialTransactionAllocations.splice(indx, 1);
        this.selectedItem.financialTransactionAllocations.push({
          lineSerial: item.lineSerial,
          serviceId: item.serviceId,
          itemTransactionId: item.id,
          value: item.voucherValue,
          financialTransactionId: this.id ? this.id : 0,
          allocatedValue: item.allocatedValue,

        });
      }
      this.mainVal = 0;
      this.selectedItem.financialTransactionAllocations.forEach(data => {
        data.voucherValue = data.voucherValue ? data.voucherValue : 0;
        this.mainVal += data.voucherValue
        this.totalAll = Number(this.selectedItem.voucherValue) - this.mainVal;
        this.totalAllocatedValue = this.totalAll;
        var val = this.id ? (Number(this.selectedItem.voucherValue) - this.lastPaid) - this.allPaid : Number(this.selectedItem.voucherValue) - this.allPaid;
        if (val < 0) {
          this.moreThan = true;
          this.doneAlert({ text: this.$t('allocationValueIsMoreThanMainValue'), type: 'warning' });
        } else {
          this.moreThan = false;
        }
      });
     // this.closeInvModal();
    },

    openInvModal() {
      this.totalAllocatedValue = this.selectedItem.voucherValue;
      if (this.id > 0 && this.selectedItem.studentId) {
        this.getAllInv();
      } else {
        this.$refs['inv-modal'].show();
      }
    },
    getAllInv() {
      this.get({ url: `Invoices/services/${this.selectedItem.studentId}?pageNumber=${1}&pageSize=${50}&studentId=${this.selectedItem.studentId}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          this.totalNet = 0;
          this.totalRemainder = 0;
          this.totalPaid = 0;
          this.totalDiscount = 0;
          data.forEach((item) => {
            item.paid = 0;
            this.totalPaid += (item.paid + item.allocatedValue);
            this.totalNet += (item.net - item.returnTotal);
            item.allocatedValue = item.allocatedValue ? item.allocatedValue : 0;
            this.totalDiscount = item.allocateDiscount ? item.allocateDiscount : 0;
            this.totalRemainder += (item.net - (item.paid + item.allocatedValue + item.allocateDiscount + item.returnTotal));
            item.paid = this.fraction(item.paid + item.allocatedValue);
            item.remainder = this.fraction(item.net - (item.allocatedValue + item.allocateDiscount + item.returnTotal));
            item.transactionDate = this.getDate(item.transactionDate);
            item.net = this.fraction((item.net - item.returnTotal));
            item.value = 0;
          });
          this.alldata = data;
          // this.$refs['items-table'].refreshTable();
        }).then(() => {
          this.$refs['inv-modal'].show();
        })
        .catch(() => {
          this.isTableBusy = false;
        });
      return null;
    },
    calculateTaxAndOrifinalValue(baseValue) {
      if (this.isTaxAppliedOnStudent) {
        this.selectedItem.originalValue = (baseValue / 1.15).toFixed(2);
        this.selectedItem.taxValue = (baseValue - this.selectedItem.originalValue).toFixed(2)
      } else {
        this.selectedItem.originalValue = this.selectedItem.voucherValue;
        this.selectedItem.taxValue = 0;
      }
    },

    filterFromStudents(selectedStudent) {
      this.filter.studentId = selectedStudent.id;
      // then display data needed for him
      this.guardianName = this.isRight ? selectedStudent.guardianNameAr : selectedStudent.guardianNameEn;
      this.guardianMobileNumber = selectedStudent.guardianMobile;
      this.isTaxAppliedOnStudent = selectedStudent.isTaxable
      this.remainingBalance = selectedStudent.remainingBalance;
    },

    closeInvModal() {
      this.allPaid = 0;
    },
    getBanks() {
      this.get({ url: 'Safes' }).then((data) => {
        this.safes = data;
      });
      this.get({ url: 'Banks' }).then((data) => {
        this.Banks = data;
      });
      this.get({ url: 'PaymentMethods' }).then((data) => {
        this.payments = data;
      })
    },

    getData() {
      if (
        this.pathName === 'collection-transactions-new'
        || this.pathName === 'collection-transactions-edit'
      ) {
        this.get({
          url: 'CollectionVouchers',
          id: this.$route.params.id,
        }).then((data) => {
          data.financialTransactionAllocations.forEach(item => {
              this.lastPaid += item.value;
              item.value = 0 + item.value;
            });
          this.selectedItem = data;
        }).then(() => {
          this.guardianName = this.selectedItem.guardianName
          this.guardianMobileNumber = this.selectedItem.guardianMobileNumber
          this.isTaxAppliedOnStudent = this.selectedItem.isTaxableStduent
          this.remainingBalance = this.selectedItem.remainingBalance
        })
      } else {
        this.get({
          url: 'PaymentVouchers',
          id: this.$route.params.id,
        }).then((data) => {
          this.selectedItem = data;
          this.selectedItem.financialTransactionAllocations = []
        }).then(() => {
          this.guardianName = this.selectedItem.guardianName
          this.guardianMobileNumber = this.selectedItem.guardianMobileNumber
          this.isTaxAppliedOnStudent = this.selectedItem.isTaxableStduent
          this.remainingBalance = this.selectedItem.remainingBalance
        })
      }
    },

    isValidVoucherValuePaid() {
      if (this.selectedItem.voucherValue - this.allPaid > 0) {
        this.doneAlert({ text: this.$t('amountMustBeAllocated'), type: 'warning' });
        return false
      }

      // all is well
      return true
    },

    isValidPayment() {
       // if no payment method selected
       if (!this.selectedItem.paymentMethod) {
        this.doneAlert({ text: this.$t('shouldSelectPaymentMethod'), type: 'warning' });
        return false
      }

      // if cash payment method selected and not select a safe
      if (this.selectedItem.paymentMethod === 'cash' && !this.selectedItem.safeId) {
        this.doneAlert({ text: this.$t('shouldSelectSafe'), type: 'warning' });
        return false
      }

      // if other payment method selected and not select a bank
      if (this.selectedItem.paymentMethod === 'other' && !this.selectedItem.paymentMethodId) {
        this.doneAlert({ text: this.$t('shouldSelectPaymentType'), type: 'warning' });
        return false
      }

      // all is well
      return true
    },

    save(type) {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      if (!this.isValidPayment()) { return }

      this.selectedItem.branchId = this.branchId;
      if (
        this.pathName === 'collection-transactions-new'
        || this.pathName === 'collection-transactions-edit'
      ) {
        if (this.id > 0) {
          this.update({
            url: 'CollectionVouchers',
            data: this.selectedItem,
            id: this.id,
          })
            .then(() => {
              if (type === 'cancel') {
                this.doneAlert({ text: this.$t('allocationCancelledSuccessfully') });
              } else {
                this.doneAlert({ text: this.$t('updatedSuccessfully') });
                if (type === 'saveAndPrint') this.print(this.selectedItem);
              }
              this.$router.push({ name: 'collection-transactions' });
            });
        } else {
          this.selectedItem.transactionTime = this.getTime();
          this.create({
            url: 'CollectionVouchers',
            data: this.selectedItem,
          })
            .then((data) => {
              this.doneAlert({
                text: this.$t('savedSuccessfullyWithCode', {
                  code: data.code,
                }),
              });
              if (type === 'saveAndPrint') this.print(data);
              this.$router.push({ name: 'collection-transactions' });
            });
        }
      } else if (
        this.pathName !== 'collection-transactions-new'
        || this.pathName !== 'collection-transactions-edit'
      ) {
        if (this.id > 0) {
          this.update({
            url: 'PaymentVouchers',
            data: this.selectedItem,
            id: this.id,
          })
            .then(() => {
              this.doneAlert({ text: this.$t('savedSuccessfully') });
              if (type === 'saveAndPrint') this.print(this.selectedItem);
              if (type === 'cancel') {
                window.location.reload();
              } else {
                this.$router.push({ name: 'payment-transactions' });
              }
            });
        } else {
          this.selectedItem.transactionTime = this.getTime();
          this.create({
            url: 'PaymentVouchers',
            data: this.selectedItem,
          })
            .then((data) => {
              this.doneAlert({
                text: this.$t('savedSuccessfullyWithCode', {
                  code: data.code,
                }),
              });
              if (type === 'saveAndPrint') this.print(data);
              this.$router.push({ name: 'payment-transactions' });
            });
        }
      }
    },

    print(item) {
      const reportName = this.isRight ? 'FinancialTransaction-ar' : 'FinancialTransaction-en';
      const printedItem = {
        id: item.id,
        voucherType: item.voucherType === 'collectionDebenture' ? 0 : 1
      }
      this.printReport(reportName, printedItem);
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  .cancel-allocation {
    margin-bottom: 10px;
  }
}
</style>
